.recall-modal {
  max-width: var(--grid-column4);

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
    color: var(--color-grey-03);
  }

  &__fields {
    margin-top: 30px;
  }

  &__field {

  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }


  &__button {
    flex: 0 0 auto;
    width: 100%;
  }

  &__policy {
    flex: 0 1 100%;
    &_top{
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@include respond-up('large') {
  .recall-modal {
    &__bottom {
      margin-top: 40px;
    }
  }
}

@include respond-up('s-large') {
  .recall-modal {
    &__subtitle {
      margin-top: 10px;
      font-size: 17px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &__bottom {
      margin-top: 30px;
    }
  }
}

@include respond('s-large') {
  .recall-modal {
  }
}

@include respond-up('medium') {
  .recall-modal {
    &__policy {
      &.policy-checkbox {
        label {
          &::before {
            top: 3px;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .recall-modal {
    max-width: calc(var(--grid-column4) - 80px);

    &__bottom {
      margin-top: 25px;
    }

    &__policy {
      max-width: 200px;
    }
  }
}

@include respond-down('medium') {
  .recall-modal {
    &__subtitle {
      margin-top: 4px;
      font-size: 16px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 18px;
      }
    }
  }
}

@include respond-down('small') {
  .recall-modal {
    &__bottom {
      flex-direction: column;
      margin-top: 20px;

      div:first-child {
        width: 100%;
      }
    }

    &__button {
      margin-top: 20px;
    }
  }
}