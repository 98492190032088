.footer {
  background: var(--background-footer-color);

  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"]';

    #{$inputs}, textarea, select {
      background-color: var(--color-add-04);

      &::placeholder {
        color: var(--color-grey-03);
      }
    }
  }

  &__files-list {
    flex-wrap: wrap;
  }

  &__line-wrap{
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--color-grey-02);
    }
  }

  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__bottom-inner-wrap {
    display: flex;
  }

  &__field {
    .footer {
      &__input {
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px var(--background-footer-color) inset;
        }
      }
    }
  }

  &__submit-policy-wrap {
    display: flex;
  }

  &__policy-wrap {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: var(--size);
    line-height: var(--line-height);

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__files-list {
    display: flex;
  }

  &__link-svg {
    svg {
      path {
        stroke: var(--color-main-01);
      }
    }
  }

  &__link-svg-two {
    svg {
      path {
        fill: var(--color-main-01);
      }
    }
  }

  &__rclass-link{
    color: var(--turquoise-color)
  }

  &__public-offer{
    font-size: 14px;
    font-weight: 400;
    line-height: 155%;
    font-family: var(--font);
    grid-column: 1/8;
    color: rgb(122, 122, 122);
    margin-top: 40px;
  }
}

@include respond-up('x-large') {
  .footer {
    &__title {
      width: var(--grid-column7);
    }

    &__file-link {
      align-items: center;
    }

    &__file-link-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 155%;
    }

    &__logo-wrap {
      svg {
        width: 91px;
        height: 106px;
      }
    }

    &__info-wrap {
      margin-top: 9px;
    }

    &__schedule-item {
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &__address {
      margin-top: 4px;
    }

    &__social-wrap {
      margin-top: 20px;
    }

    &__bottom-wrap {
      padding-top: 40px;
      padding-bottom: 80px;
      margin-top: 90px;
    }
  }
}

@include respond-up('large') {
  .footer {
    padding-top: 60px;

    &__policy {
      //padding: 10px;
      //margin: -10px;
      //margin: -10px -10px -10px 30px;
    }

    &__phone-email-wrap {
      margin-top: 30px;
    }

    &__contacts {
      &:before {
        height: calc(100% + 40px);
      }
    }

    &__policy-wrap {
      width: var(--grid-column3);
      margin-left: 48px;
    }

    &__submit-policy-wrap {
      margin-top: 30px;
    }

    &__files-list {
      margin-top: 60px;
    }
  }
}

@include respond('large') {
  .footer {
    &__bottom-wrap {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .footer {
    &__title {
      width: var(--grid-column6);
    }

    &__info-wrap {
      margin-top: 20px;
    }

    &__social-wrap {
      margin-top: 10px;
    }

    &__bottom-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .footer {
    --size: 13px;
    --line-height: 160%;

    &__bottom-inner-wrap {
      gap: 40px;
    }

    &__form-wrap {
      grid-column: 1/9;
    }

    &__contacts {
      grid-column: 9/13;
      padding-left: calc(var(--grid-column) + var(--grid-gap));
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: var(--color-grey-02);
        left: 0;
        width: 1px;
        bottom: -20px;
      }
    }

    &__email-wrap {
      margin-top: 10px;
    }

    &__link-wrap {
      margin-top: 20px;
    }

    &__files-list {
      gap: 40px;
    }
  }
}

@include respond('s-large') {
  .footer {
    padding-top: 40px;

    &__bottom-wrap {
      padding-top: 26px;
      padding-bottom: 30px;
    }

    &__contacts {
      &:before {
        height: calc(100% + 20px);
      }
    }

    &__form-wrap {
      padding-top: 10px;
    }

    &__phone-email-wrap {
      margin-top: 20px;
    }

    &__policy-wrap {
      margin-left: var(--grid-gap);
    }

    &__submit-policy-wrap {
      margin-top: 20px;
    }

    &__files-list {
      margin-top: 85px;
    }
  }
}

@include respond-down('s-large') {
  .footer {
    &__logo-wrap {
      svg {
        width: 47px;
        height: auto;
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__submit-policy-wrap {
      align-items: center;
    }
  }
}

@include respond('medium') {
  .footer {
    padding-top: 30px;

    &__files-list {
      gap: 30px;
    }

    &__top-wrap {
      &.grid-block {
        grid-row-gap: 0;
      }
    }

    &__contacts {
      grid-row: 1;
      grid-column: 1/7;
      display: grid;
      grid-row-gap: 20px;
      grid-column-gap: var(--grid-gap);
      grid-template-areas:
        "logo phone-email info"
        "logo link socials";
    }

    &__logo-wrap {
      width: var(--grid-column);
      grid-area: logo;
    }

    &__phone-email-wrap {
      width: var(--grid-column3);
      grid-area: phone-email;
    }

    &__info-wrap {
      width: var(--grid-column2);
      grid-area: info;
    }

    &__link-wrap {
      grid-area: link;
    }

    &__social-wrap {
      grid-area: socials;
    }

    &__form-wrap {
      grid-row: 2;
      grid-column: 1/7;
      padding-top: 42px;
      margin-top: 40px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background: var(--color-grey-02);
      }
    }

    &__bottom-wrap {
      padding-top: 20px;
      padding-bottom: 30px;
      margin-top: 40px;
    }

    &__title {
      width: var(--grid-column5);
      min-height: 72px;
    }

    &__policy-wrap {
      margin-left: var(--grid-gap);
    }

    &__submit-policy-wrap {
      margin-top: 20px;
    }

    &__files-list {
      margin-top: 60px;
      column-gap: 30px;
    }

    &__bottom-inner-wrap {
      gap: 60px;
    }

    &__public-offer{
      grid-column: 1/5;
    }
  }
}

@include respond-down('medium') {
  .footer {
    --size: 11px;
    --line-height: 150%;
  }
}

@include respond-down('small') {
  .footer {
    padding-top: 30px;

    &__files-list {
      gap: 20px;
    }

    &__top-wrap {
      &.grid-block {
        grid-row-gap: 0;
      }
    }

    &__contacts {
      grid-row: 1;
      grid-column: 1/5;
      display: grid;
      grid-column-gap: var(--grid-gap);
      grid-template-areas:
        "logo phone-email"
        "logo info"
        "logo socials"
        "logo link";
    }

    &__form-wrap {
      grid-row: 2;
      grid-column: 1/5;
      margin-top: 40px;
      padding-top: 30px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        top: 0;
        left: 0;
        background: var(--color-grey-02);
      }
    }

    &__logo-wrap {
      width: var(--grid-column);
      grid-area: logo;
    }

    &__phone-email-wrap {
      grid-area: phone-email;
    }

    &__info-wrap {
      grid-area: info;
      margin-top: 20px;
      width: calc(var(--grid-column2) + var(--grid-gap) + (var(--grid-column) / 2));
    }

    &__social-wrap {
      grid-area: socials;
      margin-top: 10px;
    }

    &__link-wrap {
      grid-area: link;
      margin-top: 30px;
    }

    &__submit-policy-wrap {
      margin-top: 20px;
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
      }
    }

    &__policy-wrap {
      margin-top: 10px;
      width: 100%;
    }

    &__files-list {
      flex-direction: column;
      row-gap: 18px;
      margin-top: 41px;
    }

    &__bottom-wrap {
      padding-top: 20px;
      padding-bottom: 30px;
      margin-top: 38px;
    }

    &__bottom-inner-wrap {
      flex-direction: column;
      row-gap: 12px;
    }

    &__bottom-wrap {
      flex-direction: column;
      row-gap: 12px;
    }

    &__title {
      min-height: 48px;
    }

    &__public-offer{
      font-size: 13px;
      grid-column: 1/5;
      margin-top: 30px;
    }
  }
}